import { cn } from "@/lib/utils";
import Link from "next/link";
import { CSSProperties } from "react";
import { Montserrat } from "next/font/google";

interface LogoProps {
  href?: string;
  className?: string;
  onClick?: () => void;
  style?: CSSProperties;
  onlyImage?: boolean;
  src?: string; // New optional src prop
  imgClassName?: string;
  textCLassName?: string;
}

const montserrat = Montserrat({
  subsets: ["latin"],
  display: "swap",
});

export default function Logo({
  href,
  imgClassName,
  className,
  onClick,
  style,
  onlyImage,
  textCLassName,
  src = "/logo.png", // Default value for src
}: LogoProps) {
  const Component = href ? Link : "span";

  return (
    <Component
      style={style}
      onClick={onClick}
      href={href as string}
      className={cn(
        "flex items-center shrink-0 space-x-2",
        className,
        montserrat.className,
      )}
    >
      <img alt="App Logo" src={src} className={cn("size-8", imgClassName)} />
      {!onlyImage && (
        <span
          className={cn(
            "text-lg font-semibold tracking-tight hidden sm:block",
            textCLassName,
          )}
        >
          RES<strong className="font-extrabold">INQ</strong>
        </span>
      )}
    </Component>
  );
}
