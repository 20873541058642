import Icons from "@/app/(helper)/integration/icons";
import Marquee from "./marquee";

const ICON_LIST = [
  "discord-icon",
  "messenger-icon",
  "whatsapp-icon",
  "slack-icon",
  "wordpress-icon",
  "shopify-icon",
  "flutter-icon",
  "kotlin-icon",
  "react-native-icon",
  "wix-icon",
  "framer-icon",
  "webflow-icon",
] as const;

type IconKeys = (typeof ICON_LIST)[number];

const getLinkPath = (iconKey: IconKeys) => {
  return `/integration/connecting-chatbot-to-${iconKey.replace("-icon", "")}`;
};

export const IntegrationSection = () => {
  return (
    <section className="container px-0 mx-auto max-w-3xl">
      <div className="pb-[60px] flex items-center justify-between max-tablet:flex-col gap-[10px]">
        <div className="w-full max-w-[1280px] overflow-x-hidden">
          <div className="relative">
            <Marquee pauseOnHover className="[--duration:50s]">
              {ICON_LIST.map((iconKey, index) => {
                const IconComponent = Icons[iconKey as IconKeys];
                if (!IconComponent) {
                  console.error(
                    `Icon component for ${iconKey} is not defined.`,
                  );
                  return null;
                }
                return (
                  <a
                    aria-label="link"
                    key={index}
                    href={getLinkPath(iconKey as IconKeys)}
                    className="w-[150px] h-[84px] bg-white rounded-[12px] border-[1px] border-solid border-black3 box-border flex items-center justify-center hover:border-cyan-500 mix-blend-overlay transition duration-500 cursor-pointer"
                  >
                    <IconComponent width={150} height={32} />
                  </a>
                );
              })}
            </Marquee>
            <Marquee reverse pauseOnHover className="[--duration:50s]">
              {ICON_LIST.slice()
                .reverse()
                .map((iconKey, index) => {
                  const IconComponent = Icons[iconKey as IconKeys];
                  if (!IconComponent) {
                    console.error(
                      `Icon component for ${iconKey} is not defined.`,
                    );
                    return null;
                  }
                  return (
                    <a
                      aria-label="link"
                      key={index}
                      href={getLinkPath(iconKey as IconKeys)}
                      className="w-[150px] h-[84px] bg-white rounded-[12px] border-[1px] border-solid border-black3 box-border flex items-center justify-center hover:border-cyan-500 mix-blend-overlay transition duration-500 cursor-pointer"
                    >
                      <IconComponent width={150} height={32} />
                    </a>
                  );
                })}
            </Marquee>
            <div className="pointer-events-none absolute inset-y-0 left-0 w-1/3 bg-gradient-to-r from-[#FFFFFF]"></div>
            <div className="pointer-events-none absolute inset-y-0 right-0 w-1/3 bg-gradient-to-l from-[#FFFFFF]"></div>
          </div>
        </div>
      </div>
    </section>
  );
};
