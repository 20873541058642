import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button, buttonVariants } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import {
  ArrowUp,
  LoaderPinwheel,
  Pause,
  Play,
  Square,
  X,
  Mic,
} from "lucide-react";
import Link from "next/link";
import { FC, useState } from "react";
import Logo from "./Logo";

const DisplayChatComponent: FC = () => {
  const [mode, setMode] = useState<"speech" | "text">("speech");
  const [turn, setTurn] = useState<"speak" | "pause" | "send" | "play">(
    "speak",
  );
  const [speaking, setSpeaking] = useState(false);
  const [messages, setMessages] = useState<
    Array<{ text: string; sender: "user" | "bot" }>
  >([{ text: "Hello! How can I assist you today?", sender: "bot" }]);

  const renderSpeechMode = () => (
    <>
      <div className="flex-1 p-2.5 flex flex-col items-center justify-center">
        {renderTurn()}
      </div>
      <div className="h-16 text-gray-800 flex items-center justify-center">
        {renderMessage()}
      </div>
      <div className="p-4 flex items-center justify-between gap-2">
        <div className="space-x-2">
          <Button
            size="icon"
            variant="ghost"
            className="rounded-full w-14 h-14 bg-gray-100 hover:bg-gray-200 shrink-0"
            onClick={() => {
              /* setTurn(turn === "speak" ? "pause" : "speak");
              setSpeaking(turn === "speak"); */
            }}
          >
            {turn === "speak" ? (
              <Pause className="w-9 h-9 text-gray-800" />
            ) : (
              <Play className="w-9 h-9 text-gray-800" />
            )}
          </Button>
          {turn === "speak" && speaking && (
            <Button
              size="icon"
              variant="ghost"
              className="rounded-full w-14 h-14 bg-gray-100 hover:bg-gray-200 shrink-0"
              onClick={() => {
                /* setTurn("send") */
              }}
            >
              <ArrowUp className="w-9 h-9 text-gray-800" />
            </Button>
          )}
        </div>
        <Button
          size="icon"
          variant="destructive"
          className="rounded-full w-14 h-14 bg-red-500 hover:bg-red-600 text-white shrink-0"
          onClick={() => {
            /* setMode("text") */
          }}
        >
          <X className="w-9 h-9" />
        </Button>
      </div>
    </>
  );

  const renderTextMode = () => (
    <>
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-4 ${
              message.sender === "user" ? "text-right" : "text-left"
            }`}
          >
            <div
              className={`inline-block p-2 rounded-lg ${
                message.sender === "user"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-200 text-gray-800"
              }`}
            >
              {message.text}
            </div>
          </div>
        ))}
      </div>
      <div className="p-4 flex items-center gap-2">
        <Input
          placeholder="Type your message..."
          className="flex-grow"
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              setMessages([
                ...messages,
                { text: e.currentTarget.value, sender: "user" },
              ]);
              e.currentTarget.value = "";
            }
          }}
        />
        <Button
          size="icon"
          variant="ghost"
          className="rounded-full w-10 h-10 bg-gray-100 hover:bg-gray-200 shrink-0"
          onClick={() => setMode("speech")}
        >
          <Mic className="w-5 h-5 text-gray-800" />
        </Button>
      </div>
    </>
  );

  const renderTurn = () => {
    if (turn === "speak") {
      return (
        <BallScale color="#00c853">
          <RecordingVisualizer />
        </BallScale>
      );
    } else if (turn === "pause") {
      return (
        <BallScale count={1}>
          <Pause size={48} />
        </BallScale>
      );
    } else if (turn === "send") {
      return (
        <BallScale>
          <LoaderPinwheel size={48} className="animate-spin" />
        </BallScale>
      );
    } else if (turn === "play") {
      return (
        <BallScale color="#2962ff">
          <PlaybackVisualizer />
        </BallScale>
      );
    }
  };

  const renderMessage = () => {
    if (turn === "speak") {
      return (
        <div className="flex-grow text-xs text-center">
          <div>{speaking ? "Listening" : "Start Speaking"}</div>
          {speaking && (
            <div className="flex items-center justify-center whitespace-nowrap">
              Send manually
              <span className="border border-gray-400 rounded-full p-0.5 mx-1">
                <ArrowUp size={16} />
              </span>
            </div>
          )}
        </div>
      );
    } else if (turn === "pause") {
      return (
        <div className="flex-grow text-xs text-center">
          <div>Paused</div>
          <div className="flex items-center justify-center">
            Play
            <span className="border border-gray-400 rounded-full p-0.5 mx-1">
              <ArrowUp size={16} />
            </span>
          </div>
        </div>
      );
    } else if (turn === "send") {
      return (
        <div className="flex-grow text-xs text-center">
          <div>Processing</div>
          <div className="flex items-center justify-center">
            Stop
            <span className="border border-gray-400 rounded-full p-0.5 mx-1">
              <Square size={16} />
            </span>
          </div>
        </div>
      );
    } else if (turn === "play") {
      return (
        <div className="flex-grow text-xs text-center">
          <div>Answering</div>
          <div className="flex items-center justify-center">
            Stop
            <span className="border border-gray-400 rounded-full p-0.5 mx-1">
              <Square size={16} />
            </span>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="bg-white flex mx-auto flex-col h-[650px] sm:w-[420px] w-[95%] rounded-lg shadow-lg border border-gray-200 overflow-hidden">
      <div className="flex items-center p-4 border-b border-gray-200">
        <Avatar className="w-12 h-12 mr-1">
          <AvatarImage
            className="object-cover"
            alt="AI Assistant"
            src="/logo_512.png"
          />
          <AvatarFallback>AI</AvatarFallback>
        </Avatar>
        <div className="flex flex-col items-start">
          <h2 className="text-md font-semibold">Resinq.com</h2>
          <p className="text-xs text-gray-500">Always here to help</p>
        </div>
      </div>
      {mode === "speech" ? renderSpeechMode() : renderTextMode()}
      <div className="flex flex-col items-center">
        <p className="text-xs text-gray-500 bg-gray-200 w-full items-center justify-center max-w-lg py-1 rounded-b-md flex flex-row mx-auto">
          Powered by{" "}
          <span>
            <Logo
              className="mx-auto pl-2 [&_img]:size-[1rem] [&_span]:text-[12px]"
              textCLassName="!block"
              href="/"
            />
          </span>
        </p>
      </div>
    </div>
  );
};

const BallScale: FC<{
  color?: string;
  children: React.ReactNode;
  count?: number;
}> = ({ color = "#78909c", children, count = 6 }) => {
  return (
    <div className="flex items-center justify-center relative">
      <div className="absolute w-[300px] h-[300px] flex items-center justify-center z-1">
        {Array.from({ length: count }).map((_, index) => (
          <div
            key={index}
            className="ripple absolute w-[150px] h-[150px] rounded-full opacity-[0.3]"
            style={{
              backgroundColor: color,
              animationDelay: `${(index - 1) * 0.5}s`,
            }}
          ></div>
        ))}
      </div>
      <div
        style={{ backgroundColor: color }}
        className="flex justify-center relative w-[150px] h-[150px] rounded-full items-center text-white text-[2em] font-bold z-[2]"
      >
        {children}
      </div>
    </div>
  );
};

const RecordingVisualizer: FC = () => {
  return (
    <div className="flex items-center justify-center space-x-1">
      {Array.from({ length: 9 }).map((_, index) => (
        <div
          key={index}
          className="w-2 bg-white rounded-full animate-pulse"
          style={{
            height: `${Math.random() * 40 + 10}px`,
            animationDelay: `${index * 0.1}s`,
          }}
        ></div>
      ))}
    </div>
  );
};

const PlaybackVisualizer: FC = () => {
  return (
    <div className="flex items-center justify-center space-x-1">
      {Array.from({ length: 9 }).map((_, index) => (
        <div
          key={index}
          className="w-2 bg-white rounded-full animate-pulse"
          style={{
            height: `${Math.random() * 40 + 10}px`,
            animationDelay: `${index * 0.1}s`,
          }}
        ></div>
      ))}
    </div>
  );
};

export default DisplayChatComponent;
