"use client";
import React, { FC, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";

interface CardProps {
  icon: string;
  title: string;
  description: string;
  span: string;
  backgroundImage?: string;
  gridColor?: string;
}

const cardVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: (custom: number) => ({
    opacity: 1,
    y: 0,
    transition: { delay: custom * 0.2, duration: 0.5 },
  }),
};

const SolutionCard: FC<CardProps & { index: number }> = ({
  icon,
  title,
  description,
  span,
  backgroundImage,
  gridColor = "rgba(0, 0, 0, 0.04)",
  index,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const gridPattern = encodeURIComponent(
    `<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32' width='32' height='32' fill='none' stroke='${gridColor}'><path d='M0 .5H31.5V32'/></svg>`,
  );

  return (
    <motion.div
      className={`relative overflow-hidden rounded-2xl p-4 md:p-6 flex flex-col items-start space-y-2 ${span} ${
        backgroundImage
          ? "bg-cover bg-center h-48 md:h-64"
          : "bg-white border border-gray-200"
      }`}
      style={
        backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : {}
      }
      variants={cardVariants}
      custom={index}
      initial="hidden"
      animate="visible"
      whileHover={{
        scale: 1.02,
        transition: { duration: 0.3 },
      }}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
    >
      <div
        className="absolute -inset-1 pointer-events-none select-none"
        style={{
          backgroundImage: `url("data:image/svg+xml,${gridPattern}")`,
          backgroundSize: "32px 32px",
          backgroundRepeat: "repeat",
          maskImage:
            "linear-gradient(to bottom right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 60%)",
        }}
      />

      {!backgroundImage && (
        <>
          <div className="z-10">
            <AnimatePresence>
              {isHovered && (
                <motion.div
                  transition={{ duration: 0.4 }}
                  className="absolute top-0 left-0 w-full h-full flex items-center justify-center opacity-30"
                  style={{ backgroundColor: gridColor }}
                ></motion.div>
              )}
            </AnimatePresence>
            <motion.img
              src={icon}
              alt={`${title} icon`}
              className="w-12 h-12 md:w-16 md:h-16 mb-2"
              animate={
                isHovered
                  ? {
                      scale: [1, 1.1, 1],
                      filter: "brightness(1.1) saturate(1.2)",
                      transition: {
                        duration: 0.5,
                        ease: "easeInOut",
                        times: [0, 0.2, 0.8, 1],
                        repeat: Infinity,
                        repeatDelay: 0.5,
                      },
                    }
                  : {}
              }
            />
            <h2 className="text-base md:text-lg font-bold mb-1">{title}</h2>
            <p className="text-sm md:text-base text-gray-600">{description}</p>
          </div>
        </>
      )}
    </motion.div>
  );
};

const SolutionCardLayout: FC = () => {
  const cards = [
    {
      icon: "/icons/dash.svg",
      title: "Customize Appearance",
      description:
        "Tailor the appearance of your chatbot to match your brand's identity. Choose from a variety of themes and colors or create your own with customization options.",
      span: "col-span-12 md:col-span-8",
      gridColor: "rgba(1, 167, 77, 0.2)", // Example of a green grid color
    },
    {
      icon: "/icons/chat.svg",
      title: "Conversation History",
      description: "Easily access and review all past chatbot conversations.",
      span: "col-span-12 md:col-span-4",
      gridColor: "rgba(58, 101, 235, 0.2)", // Example of a blue grid color
    },
    {
      icon: "/icons/sec.svg",
      title: "Security",
      description:
        "Keep your chatbot secure with our advanced security measures.",
      span: "col-span-12 sm:col-span-6 md:col-span-4",
      gridColor: "rgba(107, 50, 228, 0.2)", // Example of a purple grid color
    },
    {
      icon: "/icons/party.svg",
      title: "Integrations",
      description:
        "Effortlessly integrate your chatbot with various platforms.",
      span: "col-span-12 sm:col-span-6 md:col-span-4",
      gridColor: "rgba(170, 133, 68, 0.2)", // Example of a brown grid color
    },
    {
      icon: "/icons/data.svg",
      title: "Message Management",
      description:
        "Efficiently manage your chatbot's messages for accurate responses.",
      span: "col-span-12 sm:col-span-6 md:col-span-4",
      gridColor: "rgba(235, 68, 58, 0.2)", // Example of a red grid color
    },
    {
      icon: "/icons/voice.svg",
      title: "Text-to-Speech and Speech-to-Text",
      description:
        "Enable your chatbot to speak and understand spoken language on any platform it is embedded in.",
      span: "col-span-12 md:col-span-6",
      gridColor: "rgba(68, 71, 170, 0.2)", // Example of a blue grid color
    },
    {
      icon: "/icons/report.svg",
      title: "Analytics and Reporting",
      description:
        "Get detailed analytics and reports on your chatbot's performance to make informed decisions and improve its efficiency.",
      span: "col-span-12 md:col-span-6",
      gridColor: "rgba(255, 0, 0, 0.2)", // Example of a red grid color
    },
  ];

  return (
    <section className="container px-4 py-12 mx-auto max-w-6xl">
      <motion.div
        className="grid grid-cols-12 gap-4"
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.1,
            },
          },
        }}
      >
        {cards.map((card, index) => (
          <SolutionCard key={index} {...card} index={index} />
        ))}
      </motion.div>
    </section>
  );
};

export default SolutionCardLayout;
