"use client";
import * as React from "react";
import Image from "next/image";
import Link from "next/link";
import { EnvelopeClosedIcon } from "@radix-ui/react-icons";
import SystemHeath from "@/components/shared/SystemHealth";
import { APP_SOCIAL_MEDIAS } from "@/constants";
import { Fragment, ReactNode } from "react";
import Logo from "./Logo";

export type FooterLinks = Record<
  string,
  {
    url: string;
    label: string;
    content?: ReactNode;
  }[]
>;

export default function Footer({ links }: { links?: FooterLinks }) {
  const _links: FooterLinks = links ?? {
    ["product"]: [
      {
        url: "/pricing",
        label: "Pricing",
      },
    ],
    ["resources"]: [
      {
        url: "/help",
        label: "Help Center",
      },
      {
        url: "/integration",
        label: "Integration",
      },
      /*       {
        url: "/blog",
        label: "Blog",
      }, */
      /*       {
        url: "/contact",
        label: "Contact",
      }, */
      {
        url: "/faq",
        label: "FAQ",
      },
    ],

    ["company"]: [
      {
        url: "/support",
        label: "Support",
      },
      {
        url: "/privacy",
        label: "Privacy",
      },
      {
        url: "/terms",
        label: "Terms",
      },
    ],
    /*  ["agents"]: [
      {
        url: "/customer-support",
        label: "Customer Support Agent",
      },
      {
        url: "/sales-assistant",
        label: "Sales Assistant Agent",
      },
      {
        url: "/lead-generation",
        label: "Lead Generation Agent",
      },
      {
        url: "/faq-bot",
        label: "FAQ Bot",
      },
      {
        url: "/feedback-collector",
        label: "Feedback Collector",
      },
      {
        url: "/ecommerce-helper",
        label: "E-commerce Assistant",
      },
      {
        url: "/virtual-assistant",
        label: "Virtual Assistant",
      },
    ], */
  };

  return (
    <footer className="mx-auto w-full max-w-screen-2xl px-2.5 lg:px-20 relative z-10 overflow-hidden border border-b-0 border-gray-200 bg-white/50 pt-16 backdrop-blur-lg md:rounded-t-2xl">
      <div className="container p-6 mx-auto pt-16">
        <div className="lg:flex">
          <div className="w-full -mx-6 lg:w-2/5 ">
            <div className="px-6 space-y-4">
              <Logo textCLassName="!block" />
              <p className="max-w-sm text-muted-foreground text-sm">
                {/* Resinq uses AI technology to create chat solutions for your
                website. */}
                AI-powered chat solutions for your website or mobile app. From
                24/7 customer support to capturing leads and scheduling
                appointments, our bots engage your users in real-time,
                effortlessly.
              </p>

              <div className="flex items-center gap-2">
                {APP_SOCIAL_MEDIAS.map((social) => (
                  <a
                    href={social.url}
                    key={social.title}
                    target="_blank"
                    rel="noreferrer"
                    className="rounded-full size-9 flex justify-center items-center border border-gray-200 transition-colors hover:bg-gray-100"
                  >
                    <span className="sr-only">{social.title}</span>
                    <social.icon />
                  </a>
                ))}
              </div>
            </div>
          </div>

          <div className="mt-6 lg:mt-0 lg:flex-1">
            <div className="grid gap-6 grid-cols-2 md:grid-cols-4">
              {Object.entries(_links).map(([title, items]) => (
                <div key={title} className="space-y-4">
                  <span className="capitalize font-semibold">{title}</span>
                  {items.map((item, index) => (
                    <Fragment key={title + index}>
                      {item.content ?? (
                        <Link
                          aria-label="link"
                          href={item.url}
                          className="block text-sm text-muted-foreground hover:underline"
                        >
                          {item.label}
                        </Link>
                      )}
                    </Fragment>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-16 border-t pt-8 sm:mt-20 lg:mt-24">
          <p className="text-sm leading-5 text-muted-foreground">
            © {new Date().getFullYear()} Resinq. All rights reserved.
          </p>
        </div>
      </div>
      <div className="container">
        <div className="absolute -top-0 inset-x-0 opacity-35">
          <div
            className="absolute -z-5 inset-0 max-w-lg m-auto h-[27rem] sm:h-64 sm:max-w-7xl"
            style={{
              background:
                "linear-gradient(106.89deg, rgba(195, 255, 0, 0.11) 15.73%, rgba(0, 132, 255, 0.41) 15.74%, rgba(195, 255, 0, 0.26) 56.49%, rgba(0, 132, 255, 0.4) 115.91%)",
              filter: "blur(118px)",
            }}
          ></div>
        </div>
      </div>
    </footer>
  );
}
