import { cn } from "@/lib/utils";
import {
  BadgeCheckIcon,
  CableIcon,
  HardDriveIcon,
  RocketIcon,
  User2Icon,
} from "lucide-react";
import { motion } from "framer-motion";
import Icons from "@/app/(helper)/integration/icons";
import { useEffect, useState } from "react";

export const BentoGrid = ({
  className,
  children,
}: {
  className?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "grid md:auto-rows-[18rem] grid-cols-1 md:grid-cols-3 gap-4 max-w-7xl mx-auto ",
        className,
      )}
    >
      {children}
    </div>
  );
};

export const BentoGridItem = ({
  className,
  title,
  description,
  header,
  icon,
}: {
  className?: string;
  title?: string | React.ReactNode;
  description?: string | React.ReactNode;
  header?: React.ReactNode;
  icon?: React.ReactNode;
}) => {
  return (
    <div
      className={cn(
        "row-span-1 rounded-xl group/bento hover:shadow-xl transition duration-200 shadow-input dark:shadow-none p-4 dark:bg-black dark:border-white/[0.2] bg-white border border-gray-100 justify-between flex flex-col space-y-4",
        className,
      )}
    >
      {header}
      <div className="group-hover/bento:translate-x-2 transition duration-200">
        {icon}
        <div className="font-sans font-bold text-neutral-600 dark:text-neutral-200 mb-2 mt-2">
          {title}
        </div>
        <div className="font-sans font-normal text-neutral-600 text-xs dark:text-neutral-300">
          {description}
        </div>
      </div>
    </div>
  );
};

export function BentoGridThirdDemo() {
  return (
    <BentoGrid className="max-w-6xl mx-auto md:auto-rows-[20rem] sm:px-0 px-4">
      {items.map((item, i) => (
        <BentoGridItem
          key={i}
          title={item.title}
          description={item.description}
          header={item.header}
          className={cn("[&>p:text-lg]", item.className)}
          icon={item.icon}
        />
      ))}
    </BentoGrid>
  );
}

const SkeletonOne = () => {
  const variants = {
    initial: {
      x: 0,
    },
    animate: {
      x: 10,
      rotate: 5,
      transition: {
        duration: 0.2,
      },
    },
  };
  const variantsSecond = {
    initial: {
      x: 0,
    },
    animate: {
      x: -10,
      rotate: -5,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      whileHover="animate"
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2"
    >
      <motion.div
        variants={variants}
        className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2  items-center space-x-2 bg-white dark:bg-black"
      >
        <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
        <div className="w-full bg-gray-100 h-4 rounded-full dark:bg-neutral-900" />
      </motion.div>
      <motion.div
        variants={variantsSecond}
        className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2 items-center space-x-2 w-3/4 ml-auto bg-white dark:bg-black"
      >
        <div className="w-full bg-gray-100 h-4 rounded-full dark:bg-neutral-900" />
        <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
      </motion.div>
      <motion.div
        variants={variants}
        className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2 items-center space-x-2 bg-white dark:bg-black"
      >
        <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
        <div className="w-full bg-gray-100 h-4 rounded-full dark:bg-neutral-900" />
      </motion.div>
    </motion.div>
  );
};

type IconKey = keyof typeof Icons;

const SkeletonTwo = () => {
  const [widths, setWidths] = useState<number[]>([]);

  useEffect(() => {
    const newWidths = Array.from(
      { length: 6 },
      () => Math.random() * (100 - 40) + 40,
    );
    setWidths(newWidths);
  }, []);

  const variants = {
    initial: {
      width: 0,
    },
    animate: {
      width: "100%",
      transition: {
        duration: 0.2,
      },
    },
    hover: {
      width: ["0%", "100%"],
      transition: {
        duration: 2,
      },
    },
  };

  const iconKeys: IconKey[] = [
    "discord-icon",
    "messenger-icon",
    "whatsapp-icon",
    "slack-icon",
    "wordpress-icon",
    "shopify-icon",
  ];

  const colors = [
    "rgba(88, 101, 242, 0.2)", // Discord
    "rgba(0, 153, 255, 0.2)", // Messenger
    "rgba(37, 211, 102, 0.2)", // WhatsApp
    "rgba(74, 21, 75, 0.2)", // Slack
    "rgba(33, 117, 155, 0.2)", // WordPress
    "rgba(150, 191, 72, 0.2)", // Shopify
  ];

  return (
    <motion.div
      initial="initial"
      animate="animate"
      whileHover="hover"
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2"
    >
      {widths.length > 0 &&
        widths.map((width, i) => {
          const IconComponent = Icons[iconKeys[i]];
          return (
            <motion.div
              key={"skeleton-two" + i}
              variants={variants}
              style={{
                maxWidth: `${width}%`,
                backgroundColor: colors[i],
              }}
              className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2 items-center space-x-2 bg-neutral-100 dark:bg-black w-full h-4"
            >
              <div className="flex-grow h-full bg-gray-300 dark:bg-gray-700 rounded-full"></div>
              <IconComponent className="w-5 h-5" />
            </motion.div>
          );
        })}
    </motion.div>
  );
};

/* const SkeletonThree = () => {
  const variants = {
    initial: { backgroundPosition: "0 50%" },
    animate: { backgroundPosition: ["0, 50%", "100% 50%", "0 50%"] },
  };

  const sources = [
    { id: 1, name: "Documents", icon: "/icons/document.svg" },
    { id: 2, name: "Websites", icon: "/icons/globe.svg" },
    { id: 3, name: "Text", icon: "/icons/text.svg" },
    { id: 4, name: "Q&A", icon: "/icons/qa.svg" },
  ];

  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={variants}
      transition={{
        duration: 5,
        repeat: Infinity,
        repeatType: "reverse",
      }}
      className="flex flex-1 w-full h-full min-h-[8rem] rounded-xl flex-col space-y-2 overflow-hidden p-2 animate-gradientAnimation"
      style={{
        background:
          "linear-gradient(-45deg, #000000, #333333, #555555, #222222, #000000, #222222, #555555, #333333, #000000)",
        backgroundSize: "200% 200%",
      }}
    >
      <div className="flex-1 flex items-center justify-center">
        <div className="flex-1 flex items-center justify-center">
          <motion.div
            className="w-9 h-9 bg-white/20 rounded-full flex items-center justify-center text-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1.2 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <img src="logo-bw.png" className="h-4 w-4" />
          </motion.div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        {sources.map((source, index) => (
          <motion.div
            key={source.id}
            className="bg-white/10 rounded-lg p-2 flex items-center space-x-2 border-[0.5px] border-zinc-400 shadow-slate-200"
            initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.7 + index * 0.1 }}
            whileHover={{ rotate: -5, transition: { duration: 0.1 } }}
          >
            <img
              src={source.icon}
              alt={`${source.name} icon`}
              className="w-8 h-8 md:w-6 md:h-6"
            />
            <span className="text-white text-xs">{source.name}</span>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
}; */

const SkeletonThree = () => {
  const cardVariants = (rotateValue: any) => ({
    initial: {
      x: 0,
      rotate: 0,
    },
    hover: {
      x: rotateValue,
      rotate: rotateValue,
      transition: {
        duration: 0.1,
      },
    },
  });

  const variants = {
    initial: { backgroundPosition: "0 50%" },
    animate: { backgroundPosition: ["0, 50%", "100% 50%", "0 50%"] },
  };

  const sources = [
    { id: 1, name: "Documents", icon: "/icons/document.svg" },
    { id: 2, name: "Websites", icon: "/icons/globe.svg" },
    { id: 3, name: "Text", icon: "/icons/text.svg" },
    { id: 4, name: "Q&A", icon: "/icons/qa.svg" },
  ];

  return (
    <motion.div
      initial="initial"
      animate="animate"
      variants={variants}
      transition={{
        duration: 5,
        repeat: Infinity,
        repeatType: "reverse",
      }}
      className="flex flex-1 w-full h-full min-h-[8rem] rounded-xl flex-col space-y-2 overflow-hidden p-2 animate-gradientAnimation"
      style={{
        background:
          "linear-gradient(-45deg, #000000, #333333, #555555, #222222, #000000, #222222, #555555, #333333, #000000)",
        backgroundSize: "200% 200%",
      }}
    >
      <div className="flex-1 flex items-center justify-center">
        <div className="flex-1 flex items-center justify-center">
          <motion.div
            className="w-9 h-9 bg-white/20 rounded-full flex items-center justify-center text-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1.2 }}
            transition={{ duration: 0.5, delay: 0.5 }}
          >
            <img alt="logo" src="logo-bw-only.png" className="h-4 w-4" />
          </motion.div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-2">
        {sources.map((source, index) => (
          <motion.div
            key={source.id}
            className="bg-white/10 rounded-lg p-2 flex items-center space-x-2 border-[0.5px] border-zinc-400 shadow-slate-200"
            initial={{ opacity: 0, x: index % 2 === 0 ? -50 : 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
            variants={cardVariants(index % 2 === 0 ? -5 : 5)}
            whileHover="hover"
          >
            <img
              src={source.icon}
              alt={`${source.name} icon`}
              className="w-8 h-8 md:w-6 md:h-6"
            />
            <span className="text-white text-xs">{source.name}</span>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

const SkeletonFour = () => {
  const first = {
    initial: {
      x: 20,
      rotate: -5,
    },
    hover: {
      x: 0,
      rotate: 0,
    },
  };
  const second = {
    initial: {
      x: -20,
      rotate: 5,
    },
    hover: {
      x: 0,
      rotate: 0,
    },
  };
  return (
    <motion.div
      initial="initial"
      animate="animate"
      whileHover="hover"
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-row space-x-2"
    >
      <motion.div
        variants={first}
        className="h-full w-1/3 rounded-2xl bg-white p-4 dark:bg-black dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center"
      >
        <img
          src="av-2.webp"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-10 w-10"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-500 mt-4">
          Support Agent
        </p>
        <p className="border border-red-500 bg-red-100 dark:bg-red-900/20 text-red-600 sm:text-xs text-[0.6rem] rounded-full px-2 py-0.5 mt-4 whitespace-nowrap text-center">
          Get Support
        </p>
      </motion.div>
      <motion.div className="h-full relative z-20 w-1/3 rounded-2xl bg-white p-4 dark:bg-black dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center">
        <img
          src="av-1.webp"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-10 w-10"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-500 mt-4">
          Sales Agent
        </p>
        <p className="border border-green-500 bg-green-100 dark:bg-green-900/20 text-green-600 sm:text-xs text-[0.6rem] rounded-full px-2 py-0.5 mt-4 whitespace-nowrap text-center">
          Boost Sales
        </p>
      </motion.div>
      <motion.div
        variants={second}
        className="h-full w-1/3 rounded-2xl bg-white p-4 dark:bg-black dark:border-white/[0.1] border border-neutral-200 flex flex-col items-center justify-center"
      >
        <img
          src="av-3.webp"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-10 w-10"
        />
        <p className="sm:text-sm text-xs text-center font-semibold text-neutral-500 mt-4">
          Lead Agent
        </p>
        <p className="border border-orange-500 bg-orange-100 dark:bg-orange-900/20 text-orange-600 sm:text-xs text-[0.6rem] rounded-full px-2 py-0.5 mt-4 whitespace-nowrap text-center">
          Find Leads
        </p>
      </motion.div>
    </motion.div>
  );
};
const SkeletonFive = () => {
  const variants = {
    initial: {
      x: 0,
    },
    animate: {
      x: 10,
      rotate: 5,
      transition: {
        duration: 0.2,
      },
    },
  };
  const variantsSecond = {
    initial: {
      x: 0,
    },
    animate: {
      x: -10,
      rotate: -5,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <motion.div
      initial="initial"
      whileHover="animate"
      className="flex flex-1 w-full h-full min-h-[6rem] dark:bg-dot-white/[0.2] bg-dot-black/[0.2] flex-col space-y-2"
    >
      <motion.div
        variants={variantsSecond}
        className="flex flex-row rounded-full border border-neutral-100 dark:border-white/[0.2] p-2 items-center justify-end space-x-2 w-3/4 ml-auto bg-white dark:bg-black"
      >
        <p className="text-xs text-neutral-500">
          Can you help increase my sales?
        </p>
        <div className="h-6 w-6 rounded-full bg-gradient-to-r from-pink-500 to-violet-500 flex-shrink-0" />
      </motion.div>
      <motion.div
        variants={variants}
        className="flex flex-row rounded-2xl border border-neutral-100 dark:border-white/[0.2] p-2  items-start space-x-2 bg-white dark:bg-black"
      >
        <img
          src="a-1.webp"
          alt="avatar"
          height="100"
          width="100"
          className="rounded-full h-10 w-10"
        />
        <p className="text-xs text-neutral-500">
          Yes, I can guide customers and boosts conversions.
        </p>
      </motion.div>
    </motion.div>
  );
};
const items = [
  {
    title: "Limitless Chatbot Solution",
    description: (
      <span className="text-sm">
        Enhance customer interactions and drive success with your chatbots.
      </span>
    ),
    header: <SkeletonFour />,
    className: "md:col-span-2",
    icon: <RocketIcon className="h-4 w-4 text-neutral-500" />,
  },

  {
    title: "24/7 Customer Support",
    description: (
      <span className="text-sm">
        Provide round-the-clock assistance to your customers with your chatbots.
      </span>
    ),
    header: <SkeletonFive />,
    className: "md:col-span-1",
    icon: <BadgeCheckIcon className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Lead Generation",
    description: (
      <span className="text-sm">
        Capture and qualify leads automatically to grow your business.
      </span>
    ),
    header: <SkeletonOne />,
    className: "md:col-span-1",
    icon: <User2Icon className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "One Click Integration",
    description: (
      <span className="text-sm">
        Seamlessly integrate chatbots into your website with just a few clicks.
      </span>
    ),
    header: <SkeletonTwo />,
    className: "md:col-span-1",
    icon: <CableIcon className="h-4 w-4 text-neutral-500" />,
  },
  {
    title: "Train Chatbot Easily",
    description: (
      <span className="text-sm">
        Train your chatbot using any kind of source you prefer.
      </span>
    ),
    header: <SkeletonThree />,
    className: "md:col-span-1",
    icon: <HardDriveIcon className="h-4 w-4 text-neutral-500" />,
  },
];
