"use client";
import Footer from "@/components/shared/Footer";
import { IntegrationSection } from "@/components/shared/IntegrationsSection";
import Logo from "@/components/shared/Logo";
import { BentoGridThirdDemo } from "@/components/shared/grid-card";
import SolutionCardLayout from "@/components/shared/solution-section";
import { Badge } from "@/components/ui/badge";
import { buttonVariants } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { cn } from "@/lib/utils";
import { colord } from "colord";
import { AnimatePresence, motion } from "framer-motion";
import {
  BarChart,
  BookIcon,
  CheckCircle,
  GitBranch,
  Globe,
  HelpCircle,
  Info,
  RefreshCw,
  Settings,
  Shield,
  TrendingUp,
  Zap,
} from "lucide-react";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import DisplayChatComponent from "@/components/shared/voicedemo";
import { useAuth } from "@/lib/hooks";

// Define the chatbots data with different themes

const chatbots = [
  {
    description:
      "How can I build a chatbot without extensive technical knowledge?",
    icon: HelpCircle,
    color: "rgba(181, 205, 182, 1)", // #55efc4 with 80% opacity
  },
  {
    description: "What's the easiest way to add a chatbot to my site or app?",
    icon: Zap,
    color: "rgba(209, 200, 235, 1)", // #fdcb6e with 80% opacity
  },
  {
    description: "How do I integrate my chatbot with Slack, Discord, etc.?",
    icon: GitBranch,
    color: "rgba(211, 212, 206, 1)", // #81ecec with 80% opacity
  },
  {
    description: "How can I keep my chatbot content fresh?",
    icon: RefreshCw,
    color: "rgba(234, 204, 204, 1)", // #a29bfe with 80% opacity
  },
  {
    description: "How do I manage and analyze chatbot interactions?",
    icon: BarChart,
    color: "rgba(197, 197, 204, 1)", // #ff7675 with 80% opacity
  },
  {
    description: "How do I customize my chatbot's appearance?",
    icon: Settings,
    color: "rgba(211, 213, 205, 1)", // #fab1a0 with 80% opacity
  },
  {
    description: "How can I secure my chatbot and protect data?",
    icon: Shield,
    color: "rgba(239, 215, 206, 1)", // #e17055 with 80% opacity
  },
  {
    description: "How do I use text-to-speech to enhance UX?",
    icon: Info,
    color: "rgba(218, 218, 199, 1)", // Light Blue with 80% opacity
  },
  {
    description: "How do I handle inquiries requiring multiple sources?",
    icon: CheckCircle,
    color: "rgba(197, 219, 214, 1)", // Orange with 80% opacity
  },
  {
    description: "How can I make my chatbot multilingual?",
    icon: Globe,
    color: "rgba(228, 196, 207, 1)", // Red with 80% opacity
  },
  {
    description: "How do I measure my chatbot's performance?",
    icon: BarChart,
    color: "rgba(224, 216, 205, 1)", // Green with 80% opacity
  },
  {
    description: "What are best practices for chatbot training?",
    icon: TrendingUp,
    color: "rgba(228, 228, 228, 1)", // Yellow with 80% opacity
  },
  /* {
    description:
      "How can I automate support without losing the personal touch?",
    icon: UserCheck,
    color: "rgba(224, 216, 205, 1)", // Green with 80% opacity
  },
  {
    description: "How do I integrate my chatbot with CRM and other systems?",
    icon: GitBranch,
    color: "rgba(197, 219, 214, 1)", // Orange with 80% opacity
  },
  {
    description: "How can I scale my chatbot as my business grows?",
    icon: Users,
    color: "rgba(211, 212, 206, 1)", // #81ecec with 80% opacity
  },
  {
    description: "What are the latest trends in chatbot technology?",
    icon: Star,
    color: "rgba(181, 205, 182, 1)", // #55efc4 with 80% opacity
  }, */
];

const numberOfDots = 5;

const dotVariants = {
  initial: { x: "-10%", opacity: 0 },
  animate: {
    x: "110%",
    opacity: [0, 1, 1, 0],
    transition: {
      x: { duration: 10, ease: "linear" },
      opacity: { duration: 10, times: [0, 0.1, 0.9, 1] },
    },
  },
};

// Animation variants for the icons and text
const iconVariants = {
  enter: { opacity: 0, y: 20, filter: "blur(10px)" },
  center: {
    opacity: 1,
    y: 0,
    filter: "blur(0px)",
    transition: {
      duration: 0.8,
      ease: [0.08, 0.65, 0.53, 0.96],
    },
  },
  exit: {
    opacity: 0,
    y: -20,
    filter: "blur(10px)",
    transition: { duration: 0.5 },
  },
};

interface Chatbox {
  name: string;
  description: string;
  image: string;
  iframeSrc: string; // URL to the iframe source
}

const chatbox: Chatbox[] = [
  {
    name: "Customer Agent",
    description:
      "Handle customer inquiries with ease and provide 24/7 support.",
    iframeSrc: "https://resinq.com/s/bot/clztrwhls000yrzin7kw57ci5",
    image: "/example-chatbots/1.png",
  },
  {
    name: "Sales Agent",
    description:
      "Boost your sales with a bot that can lead customers through the buying process.",
    iframeSrc: "https://resinq.com/s/bot/clztrybbv001913s7iqfywckt",
    image: "/example-chatbots/2.png",
  },
  {
    name: "Legal Advisor",
    description:
      "Provide instant legal guidance and information on common inquiries.",
    iframeSrc: "https://resinq.com/s/bot/clzts0dqa001d13s7pl8k9ahv",
    image: "/example-chatbots/3.png",
  },
  {
    name: "Healthcare Assistant",
    description: "Offer health-related information and appointment scheduling.",
    iframeSrc: "https://resinq.com/s/bot/clzts1ynv001h13s7phiulq4l",
    image: "/example-chatbots/4.png",
  },
  {
    name: "Language Tutor",
    description: "Help users learn new languages with interactive sessions.",
    iframeSrc: "https://resinq.com/s/bot/clzts331m001n13s7maz57dob",
    image: "/example-chatbots/5.png",
  },
  {
    name: "Career Coach",
    description: "Guide users in their career paths with personalized advice.",
    iframeSrc: "https://resinq.com/s/bot/clzts4nbl001crzinkg1w4wpq",
    image: "/example-chatbots/6.png",
  },
  {
    name: "Nutrition Advisor",
    description:
      "Offer diet and nutritional advice tailored to individual needs.",
    iframeSrc: "https://resinq.com/s/bot/clzts5uvt001r13s7lxnzutil",
    image: "/example-chatbots/7.png",
  },
  {
    name: "Mental Health Support Assistant",
    description: "Provide support and resources for mental wellness.",
    iframeSrc: "https://resinq.com/s/bot/clzts71tq002113s7lxs7x5vi",
    image: "/example-chatbots/8.png",
  },
];

const textVariants = {
  enter: { opacity: 0, y: 20, filter: "blur(10px)" },
  center: {
    opacity: 1,
    y: 0,
    filter: "blur(0px)",
    transition: {
      duration: 0.8,
      ease: [0.08, 0.65, 0.53, 0.96],
      delay: 0.2,
    },
  },
  exit: {
    opacity: 0,
    y: -50,
    filter: "blur(10px)",
    transition: { duration: 0.5 },
  },
};

export default function LandingPageContent() {
  const initialVisibleCards = [0, 10, 2, 4, 6, 7, 9, 1];
  const [visibleCards, setVisibleCards] = useState(initialVisibleCards);
  const { user } = useAuth();
  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const handleMouseEnter = (index: number) => {
    setActiveIndex(index);
  };

  const handleMouseLeave = () => {
    setActiveIndex(null);
  };

  const handleClick = (src: string) => {
    window.location.href = src; // Navigate to iframeSrc on mobile click
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setVisibleCards((prevVisibleCards) => {
        const newVisibleCards = [...prevVisibleCards];
        const numCardsToUpdate = Math.floor(Math.random() * 3) + 1; // Randomly choose 1 to 3 cards to update

        // Ensure at least 5 cards remain visible at all times
        const hiddenCards = chatbots
          .map((_, index) => index)
          .filter((index) => !newVisibleCards.includes(index));
        const visibleCardsToHide =
          newVisibleCards.length > 5
            ? newVisibleCards.filter((index) =>
                hiddenCards.length < numCardsToUpdate
                  ? true
                  : Math.random() > 0.5,
              )
            : [];

        for (let i = 0; i < numCardsToUpdate; i++) {
          if (visibleCardsToHide.length > 0 && newVisibleCards.length > 10) {
            const randomIndex = Math.floor(
              Math.random() * visibleCardsToHide.length,
            );
            newVisibleCards.splice(
              newVisibleCards.indexOf(visibleCardsToHide[randomIndex]),
              1,
            );
            visibleCardsToHide.splice(randomIndex, 1);
          } else if (hiddenCards.length > 0) {
            const randomIndex = Math.floor(Math.random() * hiddenCards.length);
            newVisibleCards.push(hiddenCards[randomIndex]);
            hiddenCards.splice(randomIndex, 1);
          }
        }

        return newVisibleCards;
      });
    }, 1500); // Change cards every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="container px-0 mx-auto max-w-6xl">
        <div className="grid sm:grid-cols-[2fr_1fr] items-center gap-2 py-12">
          <div>
            <section className="space-y-6">
              <div className="container flex sm:items-start items-center justify-center max-w-[72rem] flex-col gap-4 sm:gap-2">
                <div
                  className="absolute -z-10 inset-0 max-w-lg m-auto h-[27rem] sm:h-64 sm:max-w-7xl opacity-35"
                  style={{
                    background:
                      "linear-gradient(106.89deg, rgba(195, 255, 0, 0.11) 15.73%, rgba(0, 132, 255, 0.41) 15.74%, rgba(195, 255, 0, 0.26) 56.49%, rgba(0, 132, 255, 0.4) 115.91%)",
                    filter: "blur(118px)",
                  }}
                />
                <svg
                  className="absolute inset-0 -z-10 h-full w-full stroke-foreground/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
                  aria-hidden="true"
                >
                  <defs>
                    <pattern
                      id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
                      width="200"
                      height="200"
                      x="50%"
                      y="-1"
                      patternUnits="userSpaceOnUse"
                    >
                      <path d="M.5 200V.5H200" fill="none"></path>
                    </pattern>
                  </defs>
                  <svg
                    x="50%"
                    y="-1"
                    className="overflow-visible fill-muted-foreground/10"
                  >
                    <path
                      d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                      strokeWidth="0"
                    />
                  </svg>
                  <rect
                    width="100%"
                    height="100%"
                    strokeWidth="0"
                    fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
                  />
                </svg>

                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.9 }}
                  className=""
                />
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: 0.9 }}
                  className="sm:hidden"
                >
                  <Logo
                    src="/logo.png"
                    onlyImage
                    className="mr-2 [&>img]:size-24"
                  />
                </motion.div>

                <motion.h1
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                  className="text-4xl sm:text-6xl font-alber font-bold tracking-tighter text-balance sm:text-start text-center"
                >
                  Intelligent chatbot solutions for your website and mobile app
                </motion.h1>
                <motion.p
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                  className="max-w-[50rem] font-alber leading-normal text-muted-foreground sm:text-xl sm:leading-8 text-balance sm:text-start text-center"
                >
                  From 24/7 customer support to capturing leads and scheduling
                  appointments, our bots engage your users in real-time,
                  effortlessly.
                </motion.p>
                <div className="flex items-start ">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className="grid grid-cols-2 gap-4"
                  >
                    <Link
                      aria-label="link"
                      href={
                        user
                          ? `/org/${user.default_organization_id}/bot`
                          : "/auth"
                      }
                      className={cn(buttonVariants({ size: "xl" }))}
                    >
                      <Logo
                        src="/logo.png"
                        onlyImage
                        className="mr-2 [&>img]:size-5"
                      />
                      Get Started
                    </Link>

                    <Link
                      aria-label="link"
                      href="/help"
                      className={cn(
                        buttonVariants({ variant: "outline", size: "xl" }),
                        "bg-white",
                      )}
                    >
                      <BookIcon className="h-4 w-4 mr-2 shrink-0" /> Learn More
                    </Link>
                  </motion.div>
                </div>
              </div>
            </section>
          </div>
          <div className="hidden sm:flex justify-end pr-4">
            <iframe
              title="bot"
              sandbox="allow-modals allow-pointer-lock allow-popups-to-escape-sandbox allow-popups allow-presentation allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-forms"
              allow="geolocation *; gyroscope *; microphone *; midi *; clipboard-read *; clipboard-write *; serial *; xr-spatial-tracking *; autoplay *; fullscreen *;"
              src="https://resinq.com/s/bot/clzqwt4px001a54mzvdnzaetg"
              className="min-h-[38rem] w-full rounded-2xl shadow-2xl"
            />
          </div>
        </div>
      </div>

      <BentoGridThirdDemo />
      <section className="w-full py-12 md:py-16 lg:py-20">
        <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6">
          <div className="space-y-3 pr-4">
            <Badge
              variant="outline"
              className="text-xs font-alber leading-[normal] items-center justify-center inline-block px-4 py-2"
            >
              <span>FLEXIBLE COMMUNICATION</span>
            </Badge>
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl max-w-3xl mx-auto text-balance font-alber font-bold tracking-tighter leading-[normal] sm:text-5xl md:text-5xl"
            >
              Text or Speech: It's Up to You
            </motion.h2>
            <motion.h3
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-xl max-w-4xl font-alber text-zinc-600 font-normal tracking-tighter leading-[normal] sm:text-xl md:text-xl pb-4"
            >
              Choose between text and speech for chatbot interactions. Resinq
              supports both advanced text-to-speech (TTS) and speech-to-text
              (STT) features, making your chatbot versatile and accessible.
              Engage your audience in their preferred way with natural, lifelike
              conversations.
            </motion.h3>
          </div>

          <DisplayChatComponent />
        </div>
      </section>

      <section className="w-full pt-24">
        <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6">
          <div className="space-y-3">
            <Badge
              variant="outline"
              className="text-xs font-alber leading-[normal] items-center justify-center inline-block px-4 py-2"
            >
              <span>THE PROBLEM</span>
            </Badge>
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl max-w-3xl mx-auto text-balance font-alber font-bold tracking-tighter leading-[normal] sm:text-5xl md:text-5xl"
            >
              Creating and managing chatbots is often complex and time-consuming
            </motion.h2>
            <motion.h3
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-xl max-w-3xl font-alber text-zinc-600 font-normal tracking-tighter leading-[normal] sm:text-xl md:text-xl"
            >
              Many businesses face significant challenges when it comes to
              developing and maintaining effective chatbot solutions. From the
              lack of technical expertise to the need for seamless integrations,
              the process can be overwhelming.
            </motion.h3>
          </div>
        </div>
      </section>
      <section className="w-full py-12 md:py-16 lg:py-20">
        <div className="container grid gap-6 md:gap-8">
          <div className="grid grid-cols-2 sm:grid-cols-1 md:grid-cols-4 lg:grid-cols-6 gap-2 sm:gap-4 cursor-pointer">
            {chatbots.map((chatbot, index) => {
              const darkerColor = colord(chatbot.color).darken(0.4).toHex();

              return (
                <Card
                  key={index}
                  className={cn(
                    "bg-white font-alber border px-3 py-6 rounded-3xl text-center flex flex-col items-center justify-center transform transition-transform duration-500 hover:scale-105 sm:h-48 h-32",
                    index > 5 && "hidden sm:flex",
                  )}
                >
                  <AnimatePresence mode="wait">
                    {visibleCards.includes(index) && (
                      <>
                        <motion.div
                          className="flex items-center justify-center sm:size-12 size-8 rounded-xl shrink-0"
                          style={{ backgroundColor: chatbot.color }}
                          initial="enter"
                          animate="center"
                          exit="exit"
                          variants={iconVariants}
                        >
                          {React.createElement(chatbot.icon, {
                            className: `sm:size-6 size-4`,
                            style: { color: darkerColor },
                          })}
                        </motion.div>
                        <motion.h3
                          className="mt-4 text-sm font-base text-gray-700"
                          initial="enter"
                          animate="center"
                          exit="exit"
                          variants={textVariants}
                        >
                          {chatbot.description}
                        </motion.h3>
                      </>
                    )}
                  </AnimatePresence>
                </Card>
              );
            })}
          </div>
        </div>
      </section>
      <section className="w-full py-12 md:py-16 lg:py-20">
        <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6">
          <div className="space-y-3">
            <Badge
              variant="outline"
              className="text-xs font-alber leading-[normal] items-center justify-center inline-block px-4 py-2"
            >
              <span>THE SOLUTION</span>
            </Badge>
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl max-w-4xl text-pretty font-alber font-bold tracking-tighter leading-[normal] sm:text-5xl md:text-5xl"
            >
              Introducing All-in-One Solution for Chatbot Deployment and
              Management
            </motion.h2>
            <motion.h3
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-xl max-w-4xl font-alber text-zinc-600 font-normal tracking-tighter leading-[normal] sm:text-xl md:text-xl"
            >
              Our platform simplifies the creation, integration, and management
              of chatbots, enabling businesses to enhance customer interactions
              effortlessly. Whether you're a developer, marketer, or customer
              support agent, we've got you covered.
            </motion.h3>
          </div>
        </div>
      </section>
      <SolutionCardLayout />
      <section className="w-full py-12 md:py-16 lg:py-20">
        <div className="container mx-auto px-4 md:px-6">
          <div className="text-center mb-12">
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl max-w-4xl mx-auto text-pretty font-alber font-bold tracking-tighter leading-[normal] sm:text-5xl md:text-5xl"
            >
              Explore
            </motion.h2>
            <motion.h3
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-xl max-w-4xl mx-auto font-alber text-zinc-600 font-normal tracking-tighter leading-[normal] sm:text-xl md:text-xl"
            >
              Choose the perfect chatbot for your needs, designed to communicate
              effectively and efficiently.
            </motion.h3>
          </div>
          <div className="grid grid-cols sm:grid-cols-2 lg:grid-cols-4">
            {chatbox.map((bot, index) => (
              <motion.a
                key={bot.name}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className={cn(
                  "relative rounded-lg overflow-hidden duration-300 cursor-pointer",
                  index > 3 && "hidden sm:flex",
                )}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
                href={bot.iframeSrc}
              >
                {/* Overlay */}
                {activeIndex === index && (
                  <div
                    className={cn(
                      buttonVariants({ size: "xl", variant: "outline" }),
                      "z-10 absolute rounded-2xl h-full inset-0 bg-black bg-opacity-30 flex items-center justify-center text-white font-bold text-xl",
                    )}
                    style={{ pointerEvents: "none" }} // Ensures iframe click through on hover
                  >
                    Start Chatting
                  </div>
                )}

                {/* Iframe */}
                <img
                  alt={bot.name}
                  src={bot.image}
                  loading="lazy"
                  className="w-full h-full scale-95 transform rounded-2xl transition-transform duration-300"
                  style={{
                    transform:
                      activeIndex === index ? "scale(1)" : "scale(0.95)",
                    pointerEvents: activeIndex === index ? "none" : "auto",
                  }}
                ></img>
              </motion.a>
            ))}
          </div>
        </div>
      </section>
      {/* <PlaceholdersAndVanishInputJuma /> */}
      <section className="w-full py-12 md:py-16 lg:py-20">
        <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6">
          <div className="space-y-3">
            <Badge
              variant="outline"
              className="text-xs font-alber leading-[normal] items-center justify-center inline-block px-4 py-2"
            >
              <span>INTEGRATIONS</span>
            </Badge>
            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl max-w-4xl text-pretty font-alber font-bold tracking-tighter leading-[normal] sm:text-5xl md:text-5xl"
            >
              Seamlessly connect your chatbots to popular platforms and tools in
              a few clicks
            </motion.h2>
            <motion.h3
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-xl max-w-4xl font-alber text-zinc-600 font-normal tracking-tighter leading-[normal] sm:text-xl md:text-xl"
            >
              Resinq offers a wide range of integrations with leading services,
              enabling you to extend the functionality of your chatbot and
              streamline your workflows.
            </motion.h3>
          </div>
        </div>
      </section>

      <IntegrationSection />

      <section className="w-full py-12 md:py-16 lg:py-20">
        <div className="container grid items-center justify-center gap-4 px-4 text-center md:px-6">
          <div className="space-y-3">
            <Badge
              variant="outline"
              className="text-xs font-alber leading-[normal] items-center justify-center inline-block px-4 py-2"
            >
              <span>GET STARTED</span>
            </Badge>

            <motion.h2
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-3xl max-w-4xl text-pretty font-alber font-bold tracking-tighter leading-[normal] sm:text-5xl md:text-5xl"
            >
              Ready to supercharge your customer interactions with Resinq?
            </motion.h2>
            <motion.h3
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-xl max-w-4xl font-alber text-zinc-600 font-normal tracking-tighter leading-[normal] sm:text-xl md:text-xl"
            >
              Sign up today and start building your first chatbot in minutes.
            </motion.h3>
            <Link
              aria-label="link"
              href="/auth"
              className={cn(buttonVariants({ size: "xl" }))}
            >
              Get Started
            </Link>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
