import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";
import { cn } from "@/lib/utils";
const badgeVariants = cva(
  "inline-flex items-center border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary text-secondary-foreground hover:bg-secondary/80",
        white:
          "border-gray-300 bg-white text-secondary-foreground hover:bg-white/80",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground hover:bg-destructive/80",
        outline: "text-foreground",
        blue: "bg-[#2980b9]/40 text-[#2980b9]",
        orange: "bg-[#e74c3c]/40 text-[#e74c3c]",
        green: "bg-[#2ecc71]/40 text-[#2ecc71]",
        purple: "bg-[#9b59b6]/40 text-[#9b59b6]",
        cyan: "bg-[#3498db]/40 text-[#3498db]",
        discord: "bg-[#7289da]/40 text-[#7289da]",
        slack: "bg-[#4c6ca0]/40 text-[#4c6ca0]",
        telegram: "bg-[#0088cc]/40 text-[#0088cc]",
        whatsapp: "bg-[#25d366]/40 text-[#25d366]",
        messenger: "bg-[#0084ff]/40 text-[#0084ff]",
        ms_teams: "bg-[#5b5b5b]/40 text-[#5b5b5b]",
        internal: "bg-[#8e44ad]/40 text-[#8e44ad]",
      },
      rounded: {
        full: "rounded-full",
        md: "!rounded-md",
        sm: "!rounded-sm",
      },
    },
    defaultVariants: {
      variant: "default",
      rounded: "full",
    },
  },
);
export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}
function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}
export { Badge, badgeVariants };
